<template lang="pug">
.goals(v-if="getPossibleTemplates().length")
  .goals-row(v-for="goal in goals")
    CollectionPreviews.goals-row__block(
      forcedShowLink
      :name="$t(`tactic.goal.${goal}`)"
      :useCaseMap="useCaseMap"
      :templates="templatesByGoals[goal]"
    )
      template(#see-all)
        om-link.text-nowrap(primary withIconRight @click="onSeeAll(goal)") {{ $t('seeAll') }}
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import CollectionPreviews from '../CollectionPreviews.vue';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'Goals',
    components: {
      CollectionPreviews,
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
    },
    mixins: [filterHelperMixin],
    computed: {
      ...mapState(['accountType']),
      ...mapGetters('templateChooser', ['templatesByGoals']),
      ...mapState('useCase', ['useCaseMap']),
      goals() {
        return Object.keys(this.$t('tactic.goal'));
      },
    },

    methods: {
      getPossibleTemplates() {
        return Object.values(this.templatesByGoals).flat();
      },
      getRoute(slug) {
        return {
          name: 'chooser-other-listing',
          params: { type: 'goals', slug },
        };
      },
      onSeeAll(goal) {
        this.$emit('filter', { goal });
      },
    },
  };
</script>

<style lang="sass">
  .goals-row__block
    margin-bottom: 3.75rem
</style>
